"use client";

import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon, CogIcon } from "@heroicons/react/24/outline";
import { RxDashboard } from "react-icons/rx";
import { TbUsers } from "react-icons/tb";
import { LuStore, LuPhoneCall } from "react-icons/lu";
import { MdOutlineEventNote } from "react-icons/md";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { usePathname, useRouter } from "next/navigation";
import { clearToken, getUserInfoFromStorage } from "@/src/utils/storage";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface DashboardWrapperProps {
  children: React.ReactNode;
  isvendor?: boolean;
}

export function AdminDashboardWrapper({
  children,
  isvendor,
}: DashboardWrapperProps) {
  const pathname = usePathname();
  const router = useRouter();
  const [userData, setUserData] = useState<any>({});

  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const userInfo = getUserInfoFromStorage();
    setUserData({
      type: userInfo?.type,
      firstName: userInfo?.firstName,
      lastName: userInfo?.lastName,
    });
  }, []);

  const currentPath = (path: any) => {
    return pathname.startsWith(path);
  };

  const handleLogout = () => {
    if (userData?.type === "super_admin") {
      router.push("/admin/log-in");
    } else {
      router.push("/vendor/log-in");
    }

    clearToken();
  };

  const userNavigation = [
    { name: "Your profile", href: "#" },
    { name: "Sign out", action: handleLogout },
  ];

  const admintabs = [
    {
      name: "Dashboard",
      href: "/admin/dashboard",
      icon: RxDashboard,
      current: true,
    },
    {
      name: "User Management",
      href: "/admin/user-management",
      icon: TbUsers,
      current: false,
    },
    {
      name: "Vendor Management",
      href: "/admin/vendor-management",
      icon: LuStore,
      current: false,
    },
    {
      name: "Transaction History",
      href: "/admin/transaction-history",
      icon: MdOutlineEventNote,
      current: false,
    },
    {
      name: "Request Callback",
      href: "/admin/request-callback",
      icon: LuPhoneCall,
      current: false,
    },
    {
      name: "Settings",
      href: "/admin/settings",
      icon: CogIcon,
      current: false,
    },
  ];

  const vendorTabs = [
    {
      name: "Dashboard",
      href: "/vendor/dashboard",
      icon: RxDashboard,
      current: true,
    },
    {
      name: "User Management",
      href: "/vendor/user-management",
      icon: TbUsers,
      current: false,
    },
    {
      name: "Transaction History",
      href: "/vendor/transaction-history",
      icon: MdOutlineEventNote,
      current: false,
    },
  ];

  const navigation = isvendor ? vendorTabs : admintabs;

  return (
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#6F55FF] px-6 pb-4">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="w-auto"
                      src="/svgs/logo_white.svg"
                      alt="Your Company"
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <a
                                href={item.href}
                                className={classNames(
                                  item.current
                                    ? "bg-[#6F55FF] text-white"
                                    : "text-indigo-200 hover:text-white hover:bg-[#7b67ee]",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current
                                      ? "text-white"
                                      : "text-indigo-200 group-hover:text-white",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-60 lg:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-[#264FFA] px-6 pb-4">
          <div className="flex h-16 shrink-0 items-center">
            <img
              className="w-auto"
              src="/svgs/logo_white.svg"
              alt="Your Company"
            />
          </div>
          <nav className="flex flex-1 flex-col mt-10">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className={classNames(
                          currentPath(item.href)
                            ? "bg-[#5a7af9] text-white"
                            : "text-indigo-200 hover:text-white hover:bg-[#264FFA]",
                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                        )}
                      >
                        <item.icon
                          className={classNames(
                            currentPath(item.href)
                              ? "text-white"
                              : "text-indigo-200 group-hover:text-white",
                            "h-6 w-6 shrink-0"
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <div className="lg:pl-60">
        <div className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* Separator */}
          <div
            className="h-6 w-px bg-gray-900/10 lg:hidden"
            aria-hidden="true"
          />

          <div className="flex flex-1 gap-x-4 self-stretch justify-end lg:gap-x-6">
            <div className="flex items-center gap-x-4 lg:gap-x-6">
              {/* Separator */}
              <div
                className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                aria-hidden="true"
              />

              {/* Profile dropdown */}
              <Menu as="div" className="relative">
                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">Open user menu</span>
                  <img
                    className="h-8 w-8 rounded-full bg-gray-50"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <span className="hidden lg:flex lg:items-center">
                    <div className="flex flex-col ml-4 text-left">
                      <span className="text-xs text-[#666666]">
                        {userData?.type === "super_admin" ? "Admin" : "Vendor"}
                      </span>
                      <span className="text-sm font-semibold text-gray-900">
                        {`${userData?.firstName} ${userData?.lastName}`}
                      </span>
                    </div>
                    <ChevronDownIcon
                      className="ml-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <span
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer"
                            )}
                            onClick={item.action}
                          >
                            {item.name}
                          </span>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="py-10 bg-[#f5fffb] h-full">
          <div className="px-4 sm:px-6 lg:px-8">{children}</div>
        </main>
      </div>
    </div>
  );
}
