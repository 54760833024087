"use client";
import React from "react";
import { SideBar } from "../side-bar";
import { NunitoFont } from "@/src/fonts/fonts-config";
import { Navbar } from "../nav-bar";

interface DashboardWrapperProps {
  children: React.ReactNode;
  title?: string;
  subtitle?: string;
}

const DashboardWrapper = ({
  children,
  title,
  subtitle,
}: DashboardWrapperProps) => {
  return (
    <div
      className={`flex flex-col items-center min-h-screen ${NunitoFont.className}`}
    >
      <div className="w-full max-w-sm bg-[#f5fffb] px-5 has-[*:checked]:overflow-hidden relative h-full flex-1 flex flex-col">
        <Navbar />
        <main className="flex-grow flex flex-col justify-start">
          {title && (
            <h1 className="font-bold text-xl text-[#091B66]">{title}</h1>
          )}

          {subtitle && <p className="my-2 text-[#434249]">{subtitle}</p>}
          {children}
        </main>
        <SideBar />
      </div>
    </div>
  );
};

export { DashboardWrapper };
