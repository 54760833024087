"use client";

import React from "react";
import { Container } from "../../container";
import { HeyWowFont } from "@/src/fonts/fonts-config";
import Link from "next/link";

const items = [
  {
    title: "What’s EdGo and how can EdGo benefit me?",
    content:
      "EdGo is an education finance platform designed to help families plan and secure their children’s education by providing flexible savings plans combined with life insurance coverage. The platform ensures that education goals are met even in unforeseen circumstances.",
    isOpen: false,
  },
  {
    title: "Why should I trust you with my money?",
    content:
      "EdGo is powered by AIICO, an award winning, well capitalised and licensed insurer in Nigeria. So your money is in a trusted hand.",
    isOpen: false,
  },
  {
    title: "How does EdGo's savings plan work?",
    content:
      "EdGo allows you to create customized savings plans for your child’s education. You can choose to contribute monthly, quarterly, yearly, or through a one-time deposit. The savings accrue interest over time, and at maturity, the full amount plus interest is paid out.",
    isOpen: false,
  },
  {
    title: "How do I know if EdGo is right for me?",
    content:
      "EdGo is ideal for families looking for a secure, flexible way to plan for their children's education. It combines disciplined savings with insurance protection to ensure education goals are met without financial stress.",
    isOpen: false,
  },
  {
    title:
      "How do I claim my benefit in case of accident, critical illness or death?",
    content:
      "If the plan holder experiences death or a critical illness, EdGo's embedded life insurance covers the remaining savings contributions. This ensures the child’s education is funded without any financial interruptions.",
    isOpen: false,
  },
  {
    title:
      "How is EdGo different from Bank or other savings platforms I already use?",
    content:
      "EdGo is more than just a savings platform - it’s a comprehensive solution designed specifically to secure your child’s education. While banks and other platforms focus solely on savings and investments, EdGo integrates tailored education savings plans with endowment insurance to ensure uninterrupted schooling, even in the face of life’s uncertainties like illness, disability, or loss of a parent. With an easy-to-use app, customized plans for tuition and school fees, and the unmatched peace of mind that comes from knowing your child’s future is safeguarded, EdGo goes beyond financial tools to become a trusted partner in your parenting journey.",
    isOpen: false,
  },
];

const FAQs = () => {
  return (
    <Container>
      <section className="py-10 flex flex-col items-center">
        <div className="my-8">
          <h2 className="text-xl sm:text-3xl font-bold">
            Frequently Asked Questions
          </h2>
        </div>
        <div className="space-y-4 w-full max-w-3xl">
          {items.map((item, index) => (
            <AccordionItem
              key={index}
              title={item.title}
              content={item.content}
              isOpen={item.isOpen}
            />
          ))}
        </div>
      </section>
    </Container>
  );
};

type AccordionType = {
  title: string;
  content: string;
  isOpen: boolean;
};
const AccordionItem = ({ title, content, isOpen = false }: AccordionType) => {
  return (
    <details
      className="group [&_summary::-webkit-details-marker]:hidden"
      open={isOpen}
    >
      <summary className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-white border border-gray-200 p-4 text-[#434249]">
        <h4 className="text-sm sm:text-base font-medium">{title}</h4>

        <svg
          className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </summary>

      <p className="mt-4 px-4 leading-relaxed text-gray-700">{content}</p>
    </details>
  );
};

export { FAQs };
