"use client";

import React from "react";
import { Container } from "../../container";
import { HeyWowFont } from "@/src/fonts/fonts-config";
import Link from "next/link";
import { LiaPiggyBankSolid } from "react-icons/lia";
import { AiOutlineSafety } from "react-icons/ai";
import { TbHeartRateMonitor } from "react-icons/tb";
const features = [
  {
    title: "Guaranteed Education Continuity",
    description:
      "With EdGo, your child's education remains uninterrupted - even in the event of unforeseen life circumstances like critical illness, accident, or death.",
    icon: <AiOutlineSafety className="size-6 text-white" />,
    isActive: false,
  },
  {
    title: "Savings Made Simple",
    description:
      "Our personalised savings plans help you plan, save, and grow funds for school fees, extracurricular activities, and other educational needs - right from your mobile phone.",
    icon: <LiaPiggyBankSolid className="size-6 text-[#264FFA] " />,
    isActive: true,
  },
  {
    title: "Peace of Mind, Always",
    description:
      "Our endowment plan is designed to protect your child's future against the unexpected, giving you unparalleled peace of mind.",
    icon: <TbHeartRateMonitor className="size-6 text-white" />,
    isActive: false,
  },
];

const Features = () => {
  return (
    <Container>
      <div className="py-12 bg-white">
        <div className="container mx-auto flex flex-col  items-start sm:items-center text-left sm:text-center">
          <h2 className="text-2xl sm:text-4xl font-bold mb-4 ">
            Why Choose <span className="text-[#264FFA]">EdGO</span>
          </h2>
          <p className="mb-12 text-[#525252] w-full max-w-lg ">
            Because the future is uncertain, but your commitment to your child's
            education shouldn't be.
          </p>
          <ul className="grid grid-cols-1 md:grid-cols-3 gap-3">
            {features.map((feature, index) => (
              <FeatureCard
                key={index}
                title={feature.title}
                description={feature.description}
                icon={feature.icon}
                isActive={feature.isActive}
              />
            ))}
          </ul>
        </div>
      </div>
    </Container>
  );
};

type FeatureCardType = {
  title: string;
  description: string;
  icon: React.JSX.Element;
  isActive: boolean;
};
const FeatureCard = ({
  title,
  description,
  icon,
  isActive,
}: FeatureCardType) => {
  return (
    <li
      className={`p-6 py-10 text-left shadow-sm transition-colors duration-300 first:rounded-bl-3xl last:rounded-tr-3xl flex flex-col items-start ${
        isActive ? "bg-[#264FFA] text-white" : "bg-[#F2F2F2] text-[#525252]"
      }`}
    >
      <div
        className={`flex items-center justify-center mb-3 size-10 rounded-full ${
          isActive ? "bg-[#fff] " : "bg-[#264FFA]"
        }`}
      >
        {icon}
      </div>
      <h3
        className={`text-lg font-semibold mb-2 ${
          !isActive && "text-[#202020]"
        }`}
      >
        {title}
      </h3>
      <p className="leading-relaxed">{description}</p>
    </li>
  );
};

export { Features };
