"use client";

import Image from "next/image";
import { Container } from "../../container";
import BaseButton from "../../button/base-button";
import HeroImage from "../../../../../public/images/new-hero.png";

const Hero = () => {
  return (
    <section className="bg-gradient-to-b from-[#2650fa69] to-white py-16">
      <div className="container mx-auto px-4 flex flex-col items-center">
        {/* Text Content */}
        <div className="w-full max-w-xs sm:max-w-3xl sm:text-center text-left my-2 mt-4">
          <h1 className="text-2xl md:text-5xl font-bold text-gray-900 mb-4 md:leading-snug my-1 ">
            Plan and Secure your Child’s Education with{" "}
            <span className="text-[#264FFA]">EdGo</span>
          </h1>
          <p className="text-[#393939] text-lg mb-6">
            Nothing matters more than ensuring your child has access to
            continuous, quality education – no matter what life brings. At EdGo,
            we’re here to help you do just that.
          </p>
          <BaseButton
            className="px-6 py-3 shadow"
            href="/sign-up"
            variant="primary"
          >
            Get Started
          </BaseButton>
        </div>

        {/* Image Content */}
        <div className="md:w-1/2 mt-8 flex justify-center">
          <Image
            src={HeroImage} // Replace with the actual image path
            alt="Parent and child learning together"
            width={500}
            height={500}
            // className=""
          />
        </div>
      </div>
    </section>
  );
};

export { Hero };
