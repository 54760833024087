"use client";
import { useRouter } from "next/navigation";
import React from "react";
import { IoArrowBack } from "react-icons/io5";

interface DashboardHeaderProps {
  title: string;
  description?: string;
  showBack?: boolean;
}

const DashboardHeader = ({
  title,
  description,
  showBack,
}: DashboardHeaderProps) => {
  const router = useRouter();
  return (
    <div>
      {showBack && (
        <div
          onClick={() => router.back()}
          className="flex gap-1 items-center cursor-pointer mb-4"
        >
          <IoArrowBack /> Back
        </div>
      )}
      <h2 className="text-[#091B66] text-[24px] font-bold">{title}</h2>
      <p className="text-[#666666] text-[16px]">{description}</p>
    </div>
  );
};

export { DashboardHeader };
