import React from "react";
import { useController, UseControllerProps } from "react-hook-form";
interface RadioButtonGroupProps {
  label: string;
  val: string;
  id: string;
  className?: string;
  name: string;
  register: any;
}
const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  label,
  id,
  val,
  className = "",
  register,
  name,
}) => {
  return (
    <div className="flex items-center space-x-2 cursor-pointer">
      <div className="flex space-x-4 relative">
        <input
          id={id + val}
          type="radio"
          value={val}
          // checked={value === val}
          // onChange={() => onChange(val)}
          {...register(name)}
          className="relative peer shrink-0 appearance-none rounded-full size-5 border border-[#222] bg-transparent
              mt-1 p-0.5 focus:outline-none focus:ring-offset-0 focus:ring-2 focus:ring-blue-100 disabled:border-steel-400 disabled:bg-steel-400"
        />{" "}
        <div
          className="
                -left-3
                top-1
                absolute 
                w-3 h-3 mt-1
                hidden peer-checked:block
                pointer-events-none
                bg-[#22c55e] rounded-full"
        ></div>
      </div>
      <p className="text-[#434249] font-medium mt-1">{label}</p>
    </div>
  );
};
export { RadioButtonGroup };
