"use client";
import Image from "next/image";
import EdgoGuide from "../../../../../public/images/edgo-guide.png";
import BaseButton from "../../button/base-button";
import { Container } from "../../container";

const Guide = () => {
  return (
    <div className="bg-[#F2F2F2]">
      <Container>
        <div className=" mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center mt-6">
          <div>
            <h2 className="text-xl sm:text-3xl font-bold mb-1 sm:mb-4 text-[#202020] max-w-60 sm:max-w-sm">
              An Easy Guide to how <span className="text-[#264FFA]">EdGO</span>{" "}
              Works
            </h2>
          </div>
          <p className="text-[#525252] mb-6 max-w-md md:ml-12">
            With EdGO, you're not just saving—you’re building a legacy of
            security and opportunity for the next generation. Even in your
            absence, our plan ensures your goals are met.
          </p>
        </div>
        <div className=" mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Left Section: Image */}

          <div className="flex justify-start">
            <Image alt="EdGo App Screenshot" src={EdgoGuide} className="" />{" "}
          </div>

          {/* Right Section: Steps */}
          <div className="">
            <div className="space-y-12 max-w-xs md:ml-12">
              {/* Step 1 */}
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-[#264FFA] text-white rounded-full font-bold">
                  1
                </div>
                <div>
                  <h3 className="font-semibold mb-2 text-[#264FFA]">
                    Sign Up in Minutes
                  </h3>
                  <p className="text-gray-600">
                    Download the EdGo app or use this web platform and set up
                    your profile with ease. You will be required to provide your
                    child's details as well.
                  </p>
                </div>
              </div>

              {/* Step 2 */}
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-[#264FFA] text-white rounded-full font-bold">
                  2
                </div>
                <div>
                  <h3 className="font-semibold mb-2 text-gray-900">
                    Start Saving & Securing
                  </h3>
                  <p className="text-gray-600">
                    Choose a savings plan or activate an education endowment
                    plan tailored to your goals.
                  </p>
                </div>
              </div>

              {/* Step 3 */}
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-[#264FFA] text-white rounded-full font-bold">
                  3
                </div>
                <div>
                  <h3 className="font-semibold mb-2 text-gray-900">
                    Rest Easy
                  </h3>
                  <p className="text-gray-600">
                    Know that your child’s future is protected and secure—no
                    matter what happens.
                  </p>
                </div>
              </div>

              {/* Step 4 */}
              <div className="flex items-start gap-4">
                <div className="flex-shrink-0 w-8 h-8 flex items-center justify-center bg-[#264FFA] text-white rounded-full font-bold">
                  4
                </div>
                <div>
                  <h3 className="font-semibold mb-2 text-gray-900">
                    Withdraw your Savings
                  </h3>
                  <p className="text-gray-600">
                    While we encourage long-term savings to achieve educational
                    goals, you may terminate your policy and request your
                    balance at any time.
                  </p>
                </div>
              </div>
            </div>
            {/* <button className="mt-8 px-6 py-3  shadow bg-[#264FFA] text-white font-semibold rounded-lg hover:bg-blue-600">
            Get Started
          </button> */}
            <div className="md:ml-12">
              <BaseButton
                className="mt-8 px-6 py-3 shadow"
                href="/sign-up"
                variant="primary"
              >
                Get Started
              </BaseButton>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export { Guide };
