"use client";
import { HeyWowFont } from "src/fonts/fonts-config";
import React from "react";
import Select from "react-select";

interface DropdownProps {
  label?: string;
  options?: object[];
  onChange?: any;
  w?: string;
  h?: string;
  name?: string;
  multi?: boolean;
  defaultValue?: any;
  placeholder?: string;
  isDisabled?: boolean;
  error?: any;
  isClearable?: boolean;
}

export const BaseDropdown = ({
  label,
  options,
  onChange,
  w,
  h,
  defaultValue,
  placeholder,
  isDisabled,
  multi,
  name,
  error,
  isClearable = true,
  ...rest
}: DropdownProps) => {
  const colourStyles = {
    control: (styles: any) => ({
      ...styles,
      backgroundColor: "#f5fffb",
      width: w ?? "250px",
      border: "1px solid #666666",
      outline: "none",
      borderRadius: "8px",
      padding: "5px 5px 5px 0px",
      menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
    }),
    menu: (provided: any) => ({
      ...provided,
      backgroundColor: "#f5fffb", // Change this to your desired background color for the menu
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: "red",
      ":hover": {
        backgroundColor: "grey",
        color: "white",
      },
    }),
  };

  return (
    <div className={`${HeyWowFont.className} dropdown bg-[#f5fffb] flex-grow`}>
      <p className="block text-[16px] leading-6 text-gray-900">{label}</p>
      <div className="space-x-4">
        <Select
          onChange={onChange}
          options={options}
          styles={colourStyles}
          value={defaultValue}
          name={name}
          isMulti={multi}
          placeholder={placeholder}
          isDisabled={isDisabled || false}
          isClearable
          isSearchable
        />
      </div>
      {error && <p className="text-xs text-red-500">{error}</p>}
    </div>
  );
};
