import React from "react";
import { useController, UseControllerProps } from "react-hook-form";
interface CheckInputProps {
  label: string;
  val: string | boolean;
  id: string;
  className?: string;
  name: string;
  register: any;
}
const CheckInput: React.FC<CheckInputProps> = ({
  label,
  id,
  val,
  className = "",
  register,
  name,
}) => {
  return (
    <div className="flex items-center space-x-2 cursor-pointer">
      <div className="flex space-x-4 relative">
        <input
          id={id + val}
          type="checkbox"
          value={val}
          // checked={value === val}
          // onChange={() => onChange(val)}
          {...register(name)}
          className="relative peer shrink-0 appearance-none size-5 border border-[#222] rounded-sm bg-[#f5fffb]
          mt-1 p-0.5 focus:outline-none focus:ring-offset-0 focus:ring-2 focus:ring-blue-100 disabled:border-steel-400 disabled:bg-steel-400"
        />{" "}
        <svg
          className="
            -left-[14px]
            top-[2px]
            absolute 
            w-4 h-4 mt-1
            hidden peer-checked:block
            pointer-events-none"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#22c55e"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
      </div>
      <label
        htmlFor={id + val}
        className="text-[#434249] font-medium mt-1 cursor-pointer"
      >
        {label}
      </label>
    </div>
  );
};
export { CheckInput };
