"use client";

import React from "react";
import { Container } from "../../container";
import { HeyWowFont } from "@/src/fonts/fonts-config";
import Link from "next/link";

const DownloadApp = () => {
  return (
    <Container>
      <div
        className={` overflow-hidden relative shadow  rounded-3xl flex items-center justify-between flex-col sm:flex-row w-full p-4 sm:p-10 py-12 mb-5 ${HeyWowFont.className}`}
      >
        <div className="shadow-[0_40px_80px_80px_rgba(39,80,250,1)] size-32 absolute rounded-full bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white to-[#2750FA] z-10 -top-40 -right-24">
          {" "}
        </div>
        <div className="shadow-[0_-40px_60px_80px_rgba(39,80,250,1)] size-32 absolute rounded-full bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white to-[#2750FA] z-10 -bottom-48 -left-24">
          {" "}
        </div>
        <div className="basis-2/6 space-y-5">
          <p className="text-[#444444] text-sm mt-6">Download the EdGo App</p>
          <h1 className="text-[#2F2F2F] text-xl md:text-2xl lg:text-3xl font-bold my-2 max-w-72 md:max-w-80">
            Secure your child's education with EdGo
          </h1>
          <div className="flex flex-col min-[460px]:flex-row items-start min-[460px]:items-end justify-start space-y-3 min-[460px]:space-x-3 text-white">
            <Link
              className="bg-[#022B69] rounded p-2 inline-flex justify-between space-x-2"
              href={"#"}
            >
              <AppleSVG />
              <span>Get on Iphone</span>
            </Link>
            <Link
              className="bg-[#022B69] rounded p-2 inline-flex justify-between space-x-2"
              href={
                "https://play.google.com/store/apps/details?id=com.edusko.edgo"
              }
            >
              <AndroidSVG />
              <span>Get on Android</span>
            </Link>
          </div>

          <p className="text-[#444444] text-sm mt-6 max-w-56 sm:max-w-max">
            EdGo is backed by AIICO Insurance Plc.
          </p>
        </div>
        <div className="basis-4/6 flex justify-end">
          <img
            className="translate-y-12  w-auto"
            src="/images/download_app_banner.png"
            alt=""
          />
        </div>
      </div>
    </Container>
  );
};

const AppleSVG = () => (
  <svg
    width="16"
    height="18"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.0492 17.28C12.0692 18.23 10.9992 18.08 9.96919 17.63C8.87919 17.17 7.87919 17.15 6.72919 17.63C5.28919 18.25 4.52919 18.07 3.66919 17.28C-1.21081 12.25 -0.490812 4.59 5.04919 4.31C6.39919 4.38 7.33919 5.05 8.12919 5.11C9.30919 4.87 10.4392 4.18 11.6992 4.27C13.2092 4.39 14.3492 4.99 15.0992 6.07C11.9792 7.94 12.7192 12.05 15.5792 13.2C15.0092 14.7 14.2692 16.19 13.0392 17.29L13.0492 17.28ZM8.02919 4.25C7.87919 2.02 9.68919 0.18 11.7692 0C12.0592 2.58 9.42919 4.5 8.02919 4.25Z"
      fill="white"
    />
  </svg>
);

const AndroidSVG = () => (
  <svg
    width="22"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_885_13077)">
      <path
        d="M10.1387 11.4414L0.0898438 21.9696C0.202692 22.3695 0.405483 22.7383 0.682716 23.0479C0.959948 23.3574 1.30428 23.5995 1.6894 23.7555C2.07451 23.9116 2.49021 23.9775 2.90471 23.9483C3.31922 23.9191 3.72155 23.7955 4.08097 23.5869L15.3879 17.1473L10.1387 11.4414Z"
        fill="#EA4335"
      />
      <path
        d="M20.2994 9.65218L15.41 6.85156L9.90625 11.6812L15.4324 17.1326L20.2846 14.362C20.7144 14.1369 21.0745 13.7984 21.3257 13.3833C21.5769 12.9682 21.7097 12.4922 21.7097 12.007C21.7097 11.5218 21.5769 11.0459 21.3257 10.6307C21.0745 10.2156 20.7144 9.87718 20.2846 9.6521L20.2994 9.65218Z"
        fill="#FBBC04"
      />
      <path
        d="M0.0899202 1.99219C0.0292158 2.21675 -0.00101976 2.44845 2.62359e-05 2.68106V21.2812C0.000625649 21.5138 0.0308332 21.7453 0.0899202 21.9702L10.4833 11.7116L0.0899202 1.99219Z"
        fill="#4285F4"
      />
      <path
        d="M10.2136 11.9808L15.4103 6.85154L4.11845 0.381885C3.6936 0.133083 3.2104 0.00131666 2.71806 5.20116e-06C1.49626 -0.00236936 0.422112 0.808542 0.0898438 1.98437L10.2136 11.9808Z"
        fill="#34A853"
      />
    </g>
    <defs>
      <clipPath id="clip0_885_13077">
        <rect width="21.7102" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export { DownloadApp };
