import React from "react";

interface ContainerProps {
  children?: React.ReactNode;
}

const Container = ({ children }: ContainerProps) => {
  return <div className="mx-auto max-w-7xl p-4 py-8 sm:-py-12">{children}</div>;
};

export { Container };
