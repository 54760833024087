"use client";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import { Container } from "../../container";

const Testimonials = () => {
  const testimonials = [
    {
      id: "1",
      text: "\"EdGo has given me peace of mind. I know my children's education is secure, and that's priceless.\"",
      author: "Adebayo",
      role: "Dad of Four",
    },
    {
      id: "2",
      text: '"I wish I had started sooner. The app is easy to use, and the budgeting tool helps me understand how much is required for my children\'s education."',
      author: "Funmi",
      role: "Mom of Two",
    },
    {
      id: "3",
      text: '"EdGo has made saving for my children\'s education so easy and stress-free. I like that I can get my money back if nothing happens to me at the maturity of my savings. Kudos!"',
      author: "Nneka",
      role: "Mom of Three",
    },
    {
      id: "4",
      text: '"As a busy parent, I needed a solution that was reliable and simple to use. EdGo gave me exactly that. I highly recommend EdGo to every parent!"',
      author: "Tunde",
      role: "Dad of Two",
    },
  ];

  const settings = {
    infinite: true,
    speed: 6000,
    autoplaySpeed: 6000,
    arrows: false,
    autoplay: true,
    cssEase: "linear",
    pauseOnDotsHover: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 969,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="py-8 my-8 mb-16">
      <Container>
        <div className="flex flex-col items-start sm:items-center">
          <h1 className="text-xl sm:text-3xl font-bold mb-6 max-w-60 sm:max-w-sm  text-left sm:text-center">
            People who use <span className="text-[#264FFA]">EdGO</span> say a
            lot about us
          </h1>
        </div>
      </Container>
      {/* <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"> */}
      <Slider {...settings}>
        {testimonials.map((testimonial) => (
          <TestimonialCard testimonial={testimonial} key={testimonial.id} />
        ))}
      </Slider>
      {/* </div> */}
    </div>
  );
};

type TestimonialCardType = {
  id: string;
  text: string;
  author: string;
  role: string;
};

const TestimonialCard = ({
  testimonial,
}: {
  testimonial: TestimonialCardType;
}) => {
  const { text, author, role } = testimonial;

  return (
    <div className="bg-[#F6F6F6] border border-[#CCCCCC] shadow-md rounded-lg p-6 py-8 flex flex-col justify-between h-[290px] mx-2">
      <p className="text-[#333333] mb-4">{text}</p>
      <div className="flex items-center">
        {/* <Image
          src="/placeholder-avatar.png"
          alt={author}
          className="w-10 h-10 rounded-full mr-4"
          width={40}
          height={40}
        /> */}
        <div className="flex items-center justify-start gap-2">
          <span className="size-10 bg-[#D9D9D9] shadow-sm rounded-full"></span>
          <div>
            <h3 className="text-black font-bold">{author}</h3>
            <p className="text-[#848484] text-sm">{role}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Testimonials };
