"use client";
import React, { useEffect, useState } from "react";
import { ContactUsModal } from "../contact-us-modal";
import { getToken } from "@/src/utils/storage";
import WhiteLogo from "../../../../public/svgs/logo_white.svg";
import watermarkedEdgoLogo from "../../../../public/images/watermarked-edgo-logo.png";
import Image from "next/image";
import BaseButton from "../button/base-button";
import Link from "next/link";
import { HeyWowFont } from "@/src/fonts/fonts-config";

function Footer() {
  const [token, setToken] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const token = getToken();
      if (token) {
        setToken(true);
      } else {
        setToken(false);
      }
    }
  }, []);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const navigation = {
    Company: [
      { name: "About us", href: "/about" },
      { name: "FAQs", href: "/#faqs" },
      { name: "Partner Login", href: "/vendor/log-in" },
      { name: "Make a claim", href: token ? "/child-savings" : "/login" },
    ],
    legal: [
      { name: "Terms of Service", href: "/terms-of-use" },
      { name: "Privacy policy", href: "/privacy-policy" },
    ],
    "Other links": [
      { name: "Contact us", href: "contact" },
      { name: "Blog", href: "#" },
    ],
    social: [
      {
        name: "Instagram",
        href: "https://www.instagram.com/eduskoafrica",
        icon: (
          props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
        ) => (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 15.5C13.933 15.5 15.5 13.933 15.5 12C15.5 10.067 13.933 8.5 12 8.5C10.067 8.5 8.5 10.067 8.5 12C8.5 13.933 10.067 15.5 12 15.5Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M17.6361 7H17.6477"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ),
      },
      {
        name: "Twitter",
        href: "https://twitter.com/EduskoAfrica",
        icon: (
          props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
        ) => (
          <svg
            width="24"
            height="21"
            viewBox="0 0 24 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23 1.9998C22.0424 2.67528 20.9821 3.19191 19.86 3.5298C19.2577 2.83731 18.4573 2.34649 17.567 2.12373C16.6767 1.90096 15.7395 1.957 14.8821 2.28426C14.0247 2.61151 13.2884 3.1942 12.773 3.95352C12.2575 4.71283 11.9877 5.61214 12 6.5298V7.5298C10.2426 7.57537 8.50127 7.18561 6.93101 6.39525C5.36074 5.60488 4.01032 4.43844 3 2.9998C3 2.9998 -1 11.9998 8 15.9998C5.94053 17.3978 3.48716 18.0987 1 17.9998C10 22.9998 21 17.9998 21 6.4998C20.9991 6.22126 20.9723 5.9434 20.92 5.6698C21.9406 4.6633 22.6608 3.39251 23 1.9998V1.9998Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        ),
      },
      {
        name: "Facebook",
        href: "https://www.facebook.com/eduskoafrica",
        icon: (
          props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
        ) => (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 9.3V12.2H16.6C16.8 12.2 16.9 12.4 16.9 12.6L16.5 14.5C16.5 14.6 16.3 14.7 16.2 14.7H14V22H11V14.8H9.3C9.1 14.8 9 14.7 9 14.5V12.6C9 12.4 9.1 12.3 9.3 12.3H11V9C11 7.3 12.3 6 14 6H16.7C16.9 6 17 6.1 17 6.3V8.7C17 8.9 16.9 9 16.7 9H14.3C14.1 9 14 9.1 14 9.3Z"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
            <path
              d="M15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H15C20 2 22 4 22 9V15C22 20 20 22 15 22Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ),
      },
      {
        name: "YouTube",
        href: "https://www.youtube.com/channel/UCkO2k31pCdk7KVACIZFr-oQ",
        icon: (
          props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
        ) => (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M22.5401 6.42C22.4213 5.94541 22.1794 5.51057 21.8387 5.15941C21.4981 4.80824 21.0708 4.55318 20.6001 4.42C18.8801 4 12.0001 4 12.0001 4C12.0001 4 5.12008 4 3.40008 4.46C2.92933 4.59318 2.50206 4.84824 2.16143 5.19941C1.8208 5.55057 1.57887 5.98541 1.46008 6.46C1.1453 8.20556 0.991319 9.97631 1.00008 11.75C0.988863 13.537 1.14285 15.3213 1.46008 17.08C1.59104 17.5398 1.83839 17.9581 2.17823 18.2945C2.51806 18.6308 2.9389 18.8738 3.40008 19C5.12008 19.46 12.0001 19.46 12.0001 19.46C12.0001 19.46 18.8801 19.46 20.6001 19C21.0708 18.8668 21.4981 18.6118 21.8387 18.2606C22.1794 17.9094 22.4213 17.4746 22.5401 17C22.8524 15.2676 23.0064 13.5103 23.0001 11.75C23.0113 9.96295 22.8573 8.1787 22.5401 6.42V6.42Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M9.75 15.02L15.5 11.75L9.75 8.47998V15.02Z"
              stroke="white"
              stroke-opacity="0.976471"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        ),
      },
      {
        name: "LinkedIn",
        href: "https://ng.linkedin.com/in/edusko-africa-aa9b16103",
        icon: (
          props: React.JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
        ) => (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.4041 11.0835L13.3496 11.3067C13.3511 11.3075 13.3511 11.3075 13.3511 11.3075L13.3508 11.308L13.3505 11.3087L13.3499 11.3098L13.3525 11.3049C13.356 11.2986 13.3624 11.2871 13.372 11.2711C13.3911 11.239 13.4226 11.1892 13.4675 11.1276C13.5574 11.0038 13.6989 10.835 13.899 10.6654C14.2936 10.331 14.9259 9.98439 15.879 9.98439C16.7748 9.98439 17.5195 10.2053 18.0392 10.6917C18.5523 11.172 18.9272 11.9879 18.9272 13.3543V18.9988H16.8739V14.0988C16.8739 13.1561 16.6205 12.5073 16.1464 12.115C15.6897 11.7372 15.1348 11.6908 14.7569 11.6908C13.8533 11.6908 13.2627 12.0986 12.9295 12.6773C12.6207 13.2138 12.5572 13.8523 12.5572 14.323V18.9988H10.4577V10.2337H12.4041V11.0835ZM7.82952 6.64206C7.82952 7.34776 7.25743 7.91985 6.55172 7.91985C5.84602 7.91985 5.27393 7.34776 5.27393 6.64206C5.27393 5.93635 5.84602 5.36426 6.55172 5.36426C7.25743 5.36426 7.82952 5.93635 7.82952 6.64206ZM5.50197 10.2337H7.60148V18.9988H5.50197V10.2337Z"
              fill="#3578E5"
              stroke="white"
            />
          </svg>
        ),
      },
    ],
  };
  return (
    <footer
      className={`bg-[#264FFA] ${HeyWowFont.className}`}
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-4 py-8">
        <div className="my-6 flex flex-col sm:flex-row w-full">
          <div className="flex items-start flex-col my-2 sm:my-0 sm:flex-1">
            <div className="w-32 my-2 flex justify-start">
              <Image alt="Edgo Logo" src={WhiteLogo} className="" />{" "}
            </div>
            <div className="my-2">
              <p className="text-sm text-gray-50 max-w-48">
                EdGo is powered by AIICO Insurance Plc.
              </p>
            </div>
            <div className="my-2">
              <BaseButton href="/sign-up" variant="mild">
                Get Started
              </BaseButton>
            </div>
          </div>
          <div className="flex items-start flex-col my-2 mb-3 sm:mb-0 sm:my-0 sm:flex-1">
            <h3 className="my-1 text-sm font-semibold text-white">Company</h3>
            <ul role="list" className="space-y-2 sm:space-y-3">
              {navigation.Company.map((item) => (
                <li
                  onClick={(e) => {
                    if (item.href === "contact") {
                      e.preventDefault();
                      setIsOpen(true);
                    }
                  }}
                  key={item.name}
                >
                  <Link
                    href={item.href}
                    className="text-sm text-white hover:text-slate-100 "
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex items-start flex-col my-2 mb-3 sm:mb-0 sm:my-0 sm:flex-1">
            <h3 className="my-1 text-sm font-semibold text-white">Legal</h3>
            <ul role="list" className="space-y-2 sm:space-y-3">
              {navigation.legal.map((item) => (
                <li
                  onClick={(e) => {
                    if (item.href === "contact") {
                      e.preventDefault();
                      setIsOpen(true);
                    }
                  }}
                  key={item.name}
                >
                  <Link
                    href={item.href}
                    className="text-sm text-white hover:text-slate-100 "
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex items-start flex-col my-2 mb-3 sm:mb-0 sm:my-0 sm:flex-1">
            <h3 className="my-1 text-sm font-semibold text-white">
              Other links
            </h3>
            <ul role="list" className="space-y-2 sm:space-y-3">
              {navigation["Other links"].map((item) => (
                <li key={item.name}>
                  <Link
                    href={item.href}
                    className="text-sm text-white hover:text-slate-100 "
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="mt-2 flex flex-col sm:flex-row w-full">
          <div className="flex flex-col-reverse sm:flex-row justify-between sm:items-center my-4 w-full">
            <p className="text-white hover:text-gray-400 text-sm mt-6  sm:my-2">
              &copy; {new Date().getFullYear()} EdGo. All right reserved.
            </p>
            <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-6">
              <h3 className="my-1 text-sm font-semibold text-white sm:hidden">
                Socials{" "}
              </h3>

              {navigation.social.map((item) => (
                <Link
                  key={item.name}
                  href={item.href}
                  className="text-white hover:text-gray-400 inline-flex items-center space-x-1"
                >
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                  <span className="text-sm text-white hover:text-slate-100">
                    {item.name}
                  </span>
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row w-full ">
          <div className="w-full flex justify-center translate-y-8">
            <Image
              alt="edgo-watermarked"
              src={watermarkedEdgoLogo}
              className="w-10/12"
            />
          </div>
        </div>
      </div>
      <ContactUsModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </footer>
  );
}

export { Footer };
