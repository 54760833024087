import React from "react";

const KidDetailsSkeleton = () => {
  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <div className="flex items-center space-x-6 mb-6">
        <div className="h-20 w-20 bg-gray-300 rounded-full animate-pulse"></div>
        <div className="space-y-2">
          <div className="h-4 w-40 bg-gray-300 rounded"></div>
          <div className="h-3 w-32 bg-gray-200 rounded"></div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-4">
          <div className="space-y-2">
            <div className="h-3 w-24 bg-gray-300 rounded"></div>
            <div className="h-4 w-36 bg-gray-200 rounded"></div>
          </div>
          <div className="space-y-2">
            <div className="h-3 w-24 bg-gray-300 rounded"></div>
            <div className="h-4 w-36 bg-gray-200 rounded"></div>
          </div>
          <div className="space-y-2">
            <div className="h-3 w-24 bg-gray-300 rounded"></div>
            <div className="h-4 w-36 bg-gray-200 rounded"></div>
          </div>
          <div className="space-y-2">
            <div className="h-3 w-24 bg-gray-300 rounded"></div>
            <div className="h-4 w-36 bg-gray-200 rounded"></div>
          </div>
        </div>

        <div className="space-y-4">
          <div className="space-y-2">
            <div className="h-3 w-24 bg-gray-300 rounded"></div>
            <div className="h-4 w-36 bg-gray-200 rounded"></div>
          </div>
          <div className="space-y-2">
            <div className="h-3 w-24 bg-gray-300 rounded"></div>
            <div className="h-4 w-36 bg-gray-200 rounded"></div>
          </div>
          <div className="space-y-2">
            <div className="h-3 w-24 bg-gray-300 rounded"></div>
            <div className="h-4 w-36 bg-gray-200 rounded"></div>
          </div>
          <div className="space-y-2">
            <div className="h-3 w-24 bg-gray-300 rounded"></div>
            <div className="h-4 w-36 bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>

      <div className="mt-8">
        <div className="h-4 w-48 bg-gray-300 rounded mb-4"></div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="space-y-4">
            <div className="space-y-2">
              <div className="h-3 w-32 bg-gray-300 rounded"></div>
              <div className="h-4 w-40 bg-gray-200 rounded"></div>
            </div>
            <div className="space-y-2">
              <div className="h-3 w-32 bg-gray-300 rounded"></div>
              <div className="h-4 w-40 bg-gray-200 rounded"></div>
            </div>
          </div>

          <div className="space-y-4">
            <div className="space-y-2">
              <div className="h-3 w-32 bg-gray-300 rounded"></div>
              <div className="h-4 w-40 bg-gray-200 rounded"></div>
            </div>
            <div className="space-y-2">
              <div className="h-3 w-32 bg-gray-300 rounded"></div>
              <div className="h-4 w-40 bg-gray-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { KidDetailsSkeleton };
